
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EmailConfirmedSection',
  data () {
    return {
    }
  },
  components: {
  },
  mounted () {
    this.emailVerify()
  },
  methods: {
    emailVerify (data) {
      data = this.$route.query.token
      this.$store.dispatch('auth/emailVerify', data).then(
        (response) => {
          console.log(response)
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
})
