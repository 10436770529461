
import { defineComponent } from 'vue'
import EmailConfirmedSection from '@/components/sections/EmailConfirmedSection.vue'
import SecondLayout from '@/templates/SecondLayout.vue'

export default defineComponent({
  name: 'EmailConfirmed',
  components: {
    SecondLayout,
    EmailConfirmedSection
  }
})
